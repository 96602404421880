.map-container {
  height: 100%;
  position: absolute;
  top: 64px;
  bottom: 0;
  width: 100%;
}

#titleblock {
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin: 0px auto;
  top: 10px;
  width: 50%;
  max-height: 35vh;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  background: #000000c4;
  box-sizing: border-box;
}

#left-panel {
  background-color: #FFF;
  position: absolute;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  padding: 25px;
}

.toc-layer {
  width: 100%;
  padding: 10px;
}

.toc-layer:hover {
  background-color: #e6e6e6;
}

.calculation-box {
  height: 80px;
  width: 150px;
  position: absolute;
  bottom: 30px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  margin: auto;
  text-align: center;
  display: none;
  border-radius: 10px;
}

.calculated-area {
  font-family: monospace;
  font-size: x-small;
  background-color: #ffffff00;
}

p {
  font-family: 'Open Sans';
  margin: 0;
  font-size: 13px;
}

.rounded-rect {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  box-shadow: 0 0 50px -25px rgba(0, 0, 0, 0);
}

.flex-center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center.left {
  left: 0px;
}

.flex-center.right {
  right: 0px;
}

.sidebar-content {
  position: absolute;
  width: 95%;
  height: 95%;
  color: gray;
}

.sidebar-toggle {
  background: rgba(255, 255, 255, 0);
  color: #FFF;
  font-size: xx-large;
  position: absolute;
  width: 1.3em;
  height: 1.3em;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dummy {
  color: #a17e00;
}

.sidebar-toggle.left {
  right: -1em;
}

.sidebar-toggle.right {
  left: -1.5em;
}

.sidebar-toggle:hover {
  color: #0aa1cf;
  cursor: pointer;
}

.sidebar {
  transition: transform 1s;
  z-index: 3;
  width: 280px;
  top: 72px;
  display: flex;
  height: 80%;
}


.sidebar-search-toggle {
  background: rgba(255, 255, 255, 0);
  color: #FFF;
  font-size: xx-large;
  position: absolute;
  right: -1em;
  margin-top: 4em;
  width: 1.3em;
  height: 1.3em;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-group-right {
  height: 90%;
  width: auto;
  position: absolute;
  top: 72px;
  right: 50px;
  text-align: right;
  border-radius: 10px;
}

.button-group-right>*:not(:last-child) {
  margin-bottom: 10px;
}


.beforemap-select {
  position: absolute;
  bottom: 24px;
  left: 10px;
  z-index: 4;
  text-align: right;
  border-radius: 10px;
  background: #ffffff81;
}

.beforemap-select:hover {
  background: #aef3ff;
}

.aftermap-select {
  position: absolute;
  bottom: 24px;
  right: 10px;
  z-index: 4;
  text-align: right;
  border-radius: 10px;
  background: #ffffff81;
}

.aftermap-select:hover {
  background: #ffd6ae;
}

/*
The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
The toggleSidebar() function removes this class from the element in order to expand it.
*/
.left.collapsed {
  transform: translateX(-265px);
  background-color: rgb(19, 69, 168);
}

.search_container {
  position: absolute;
  width: 100%;
  top: 40px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background-color: #e4fbff;
  height: auto;
  min-height: 60px;
  max-height: 70%;
  border-radius: 8px;
  overflow: hidden;
}

.search_container fieldset {
  /* display: none; */
  background: #ccf7ff;
  border: none;
  padding: 10px;
  margin: 0;
}

.search_container input {
  display: block;
  border: none;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
}

.search_container .listing {
  overflow: auto;
  height: 80%;
  text-align: left;
}

.search_container .listing>* {
  display: block;
  padding: 5px 10px;
  margin: 0;
}

.search_container .listing a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #404;
  text-decoration: none;
}

.search_container .listing a:last-child {
  border: none;
}

.search_container .listing a:hover {
  background: #aef3ff;
}


input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

}

@media screen and (max-height: 480px) {


  #titleblock {
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    margin: 0px auto;
    left: 10px;
    width: 50%;
    margin-top: 0;
    font-size: xx-small;
  }

    
  .button-group-right {
    height: 90%;
    width: auto;
    position: absolute;
    text-align: right;
    border-radius: 10px;
  }


  .calculation-box {
    bottom: 10px;
    height: 50px;
    right: 50px;
  }

}

@media screen and (max-width: 640px)  and (max-height: 480px) {
  

  .beforemap-select {
    top: 54px;
    bottom: auto;
  }


}

@media screen and (max-width: 480px) {

  .map-container {
    position: absolute;
  }

  .searchmode {
    position: absolute;
    top: 56px;
    height: auto;
    bottom: 40%;
  }

  #titleblock {
    width: 100%;
    top: 0;
    border-radius: 0;
    background: #000000;
    height: 15vh;
    max-height: 45vh;
    font-size: x-small;

  }

  .calculation-box {
    text-align: center;
    background-color: #cdf8ff;
    z-index: 2;
    position: absolute;
    top: 60%;
    right: 0px;
    height: 34vh;
    width: 100%;
    border-radius: 0;

  }

  .calculated-area {
    font-size: xx-large;
    background-color: rgba(183, 245, 255, 0);
  }


  .sidebar {
    transition: transform 1s;
    width: 200px;
    top: 60px;
    height: 50%;
    z-index: 4;
  }


  .button-group-right {
    padding: 6px 12px;
    z-index: 1;
    position: absolute;
    top: 60%;
    right: 0px;
    height: 40%;
    width: 100%;
    border-radius: 0;
    text-align: center;
  }

  .back {

    height: auto;
    width: 50%;
    font-size: xx-small;
    left: 10px;
    top: 96px;
  }

  .beforemap-select {    
    bottom: 96px;
    left: 0px;
    width: auto;
  }
  .aftermap-select {    
    left: auto;
    right: 0px;
  }

  .button-group-right #comparebutton {

    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    border-radius: 0px;

  }

  .button-group-right #adjuststartbutton {

    position: absolute;
    bottom: 10px;
    left: 50px;
    right: 50px;
    width: 100%;
    border-radius: 0px;

  }



  .search_container {
    width: 100%;
    top: 0px;
    border-radius: 8px;
    background-color: #e4fbff;
    max-width: 100%;
    height: 80%;
    border-radius: 0px;
  }

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
