#menu {
    background: #fff;
    z-index: 1;
    top: 50px;
    left: 10px;
    border-radius: 3px;
    width: 90%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-family: 'Open Sans', sans-serif;
}

#menu a {
    font-size: 13px;
    color: #404040;
    background-color: #e3f4ff;
    display: block;
    margin: 0;
    padding: 0;
    padding: 10px;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    text-align: left;
}

#menu a:last-child {
    border: none;
}

#menu a:hover {
    background-color: #78b7e4;
    color: #404040;
}

#menu a.active {
    background-color: #3887be;
    color: #ffffff;
}

#menu a.active:hover {
    background: #3074a4;

}

@media screen and (max-width: 480px) {
    #menu {
        top: 20%;
    }

}